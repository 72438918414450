import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../../components/layout";

import { Footer } from "../../components/03_organisms/footer";
import { Bio } from "../../components/03_organisms/bio";
import listScreenshot from "./foldest/sc-list.png";
import noteScreenshot from "./foldest/sc-note.png";
import caleScreenshot from "./foldest/sc-cale.png";
import appIcon from "./foldest/icon.png";

import styles from "./foldest-style.module.css";

const FoldestLpPage = ({ data }) => {
  //  console.log(data)
  const pageTitle = "ノートアプリ「Foldest note」をリリース";
  const pageDescription =
    "ノートアプリ「Foldest note」をリリースしました。フォルダの階層を自由に作成してメモを作ることができます";
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          { name: "description", content: pageDescription },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: pageTitle },
          { property: "og:description", content: pageDescription },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + noteScreenshot,
          },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <link
          href="https://fonts.googleapis.com/css?family=Gabriela&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={styles.descBack}>
        <h1 className={styles.appName}>Foldest note</h1>
        <div className={styles.appDescription}>
          フォルダを作って階層で整理できるノートアプリ
        </div>
        <div className={styles.desc}>
          <img
            src={noteScreenshot}
            alt="フォルダを作って階層で整理できるノートアプリ"
          />
        </div>
        <div className={styles.download}>
          <div className={`${styles.desc} ${styles.descPub}`}>
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.playLink}
              href="https://play.google.com/store/apps/details?id=com.honmushi.foldestnote&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Google Play で手に入れよう"
                src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
              />
            </a>
          </div>
          <div className={`${styles.desc} ${styles.descPub}`}>
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.appLink}
              href="https://apps.apple.com/us/app/foldest-note/id1484035145?l=ja&ls=1">
              <img
                alt="app store"
                width="260px"
                src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-09-01&kind=iossoftware&bubble=ios_apps"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.descBack}>
        <div className={styles.flexRowReverse}>
          <div className={styles.descSnapshot}>
            <img src={listScreenshot} alt="フォルダで整理" />
          </div>
          <div className={styles.descText}>
            <h3>フォルダで整理</h3>
            <p>
              簡単に使えるノートアプリです。フォルダを作ってノートを管理することができ、自由に階層を作ることができます。
            </p>
            <p>
              目的や意味に合わせてフォルダを用意しておくことで、目的のノートを探しやすくなります。
            </p>
          </div>
        </div>
      </div>

      <div className={styles.descBack}>
        <div className={styles.flexRow}>
          <div className={styles.descSnapshot}>
            <img src={caleScreenshot} alt="カレンダーで表示" />
          </div>
          <div className={styles.descText}>
            <h3>カレンダー</h3>
            <p>
              ノートに予定日を登録しておくことで、カレンダー画面の日付に表示されます。
            </p>
            <p>
              日付をタップすることで、その日に登録されているノートを確認・編集できます。
            </p>
            <p>タスクや予定の管理に便利です。</p>
          </div>
        </div>
      </div>

      <div className={styles.descBack}>
        <div className={styles.desc}>
          <div className={styles.appIcon}>
            <img src={appIcon} alt="アプリアイコン"/>
          </div>
          <h4 className={styles.appSubName}>Foldest note</h4>
          <div className={styles.download}>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.playLink}
                href="https://play.google.com/store/apps/details?id=com.honmushi.foldestnote&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png"
                />
              </a>
            </div>
            <div className={`${styles.desc} ${styles.descPub}`}>
              <a
                rel="noreferrer"
                target="_blank"
                className={styles.appLink}
                href="https://apps.apple.com/us/app/foldest-note/id1484035145?l=ja&ls=1">
                <img
                  alt="app store"
                  width="260px"
                  src="https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-09-01&kind=iossoftware&bubble=ios_apps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.descBack} ${styles.descWhite}`}>
        <div className={styles.desc}>
          <Bio />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
export default FoldestLpPage;
