import React from "react";
import { Link } from "gatsby";
import { TwitterIcon } from "react-share";

import styles from "./style.module.scss";

export const Bio = () => (
  <div className={styles.profile}>
    <div className={styles.profileTitle}>profile</div>
    <a className={styles.profileContent} href="/profile/">
      <div className={styles.profileText}>
        石川県野々市市に住むフリーランスエンジニア。1年で100冊くらいの本を読む読書好きエンジニアです。応用情報とDBスペシャリストの資格を保有。
        <br />
        前職にて、大規模Web人材システムの設計・開発・運用を経験。
        現在はECサイトやWebシステムのデザイン・設計・構築、ReactNativeを使ったクロスプラットフォームアプリ開発などを行っています。
        お困りのことが有りましたらご相談ください。
      </div>
    </a>
    <div className={styles.rssLink}>
      <a
        className={styles.rssLinkIcon}
        href="https://twitter.com/koshihonmushi"
        target="_blank"
        rel="noreferrer"
        aria-label="twitter">
        <TwitterIcon size={30} round />
      </a>
      <a
        className={styles.rssLinkIcon}
        href="https://feedly.com/i/subscription/feed%2Fhttps%3A%2F%2Fhonmushi.com%2Frss.xml"
        target="_blank"
        rel="noreferrer">
        <img
          style={{ margin: "0" }}
          id="feedlyFollow"
          src="https://s3.feedly.com/img/follows/feedly-follow-circle-flat-green_2x.png"
          alt="follow us in feedly"
          width="29"
          height="29"
        />
      </a>
      <Link className={styles.rssLinkIcon} to="/rss.xml" aria-label="rss">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 48 48">
          <path
            id="shape_1"
            fill="#605f5e"
            data-name="shape1"
            d="M40.953,41H34.367A26.88,26.88,0,0,0,26.917,22.2C18.684,13.264,7,13.734,7,13.734V7s15.761-.313,25.485,11.049S40.953,41,40.953,41ZM24.094,25.958C30.211,33.637,29.5,40.924,29.5,40.924H22.918s-0.078-7.209-4.783-11.519A18.193,18.193,0,0,0,7,25.1V18.357S17.978,18.279,24.094,25.958ZM11.783,31.835A4.466,4.466,0,1,1,7.314,36.3,4.468,4.468,0,0,1,11.783,31.835Z"
          />
        </svg>
      </Link>
    </div>
  </div>
);
